<template>
  <div>
    <v-container
      fill-height
      fluid
      grid-list-xl
    >
      <v-layout
        justify-center
        wrap
      >
        <v-flex
          md12
        >
          <material-card
            color="green"
            title="Точки продаж"
            text=""
          >
            <v-data-table
              :headers="headers"
              :items="items"
              hide-actions
            >
              <template
                slot="headerCell"
                slot-scope="{ header }"
              >
                <span
                  class="subheading font-weight-light text-success text--darken-3"
                  v-text="header.text"
                />
              </template>
              <template
                slot="items"
                slot-scope="{ item }"
              >
                <td style="max-width=40px">
                  <v-btn title="Подробнее..." color="#1867c0!important" small flat icon :to="{ name: 'Точка продажи', params: { id: item.id } }" >
                    <v-icon>
                      mdi-alert-circle-outline
                    </v-icon>
                  </v-btn>
                  <v-btn title="Редактировать точку продаж" color="primary" small flat icon @click="pickToEditPlace(item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn title="Удалить точку продаж" color="error" small flat icon @click="pickToDeletePlace(item)" >
                    <v-icon>
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </td>
                <td>{{ item.name }}</td>
                <td>{{ item.address }}</td>
                <td>{{ item.currency }} </td>
              </template>
            </v-data-table>
          </material-card>
        </v-flex>
        
      </v-layout>
    </v-container>
    <v-container fluid>
      <v-layout row wrap>
          <v-btn title="Добавть новую точку продаж" color="#66bb6a" @click="addPlaceDialog = true">Добавить</v-btn>
      </v-layout>
    </v-container>
    <v-dialog v-model="addPlaceDialog" max-width="600">
      
        <v-card>
            <v-toolbar flat color="#66bb6a">
              <v-toolbar-title left color="blue" class="text--white">Новая точка продаж</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="addPlaceDialog = false">
                  <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
          <v-card-text>
            <v-form ref="form" v-model="valid">
                          
                          
                <v-layout wrap>
                    <v-flex xs4 class="font-weight-bold">Название</v-flex>
                    <v-flex xs8>
                        <v-text-field :rules="rules.required"
                                      label="Название" 
                                      v-model="newPlace.name" 
                                      ></v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout wrap>
                    <v-flex xs4 class="font-weight-bold">Адрес</v-flex>
                    <v-flex xs8>
                        <v-textarea :rules="rules.required"
                                    label="Описание" 
                                    v-model="newPlace.address"></v-textarea>
                    </v-flex>
                </v-layout>
                
                
                <v-layout wrap>
                    <v-flex xs4 class="font-weight-bold">Валюта</v-flex>
                    <v-flex xs8>
                        <v-select label="Выберите валюту"
                                  :items="currencyItems"
                                  :rules="rules.required"
                                  v-model="newPlace.currency"></v-select>
                    </v-flex>
                </v-layout>
                <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Номер венданалитики</v-flex>
                  <v-flex xs8>
                      <v-textarea 
                                  label="Серийный номер в венданалитике" 
                                  v-model="newPlace.vendAnaliticaId"></v-textarea>
                  </v-flex>
                </v-layout>
                <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Родительский контроль</v-flex>
                  <v-flex xs8>
                    <v-checkbox
                      v-model="newPlace.hasAccessControl"
                      label="Ограничить доступ к запрещенным категориям"
                    ></v-checkbox>
                  </v-flex>
                </v-layout>
                <v-layout wrap>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" @click="addPlace" class="white--text" :disabled="!valid">Сохранить</v-btn>
                    <v-btn color="red darken-1" @click.native="addPlaceDialog = false" class="white--text">Отмена</v-btn>
                </v-layout>
            </v-form>
          </v-card-text>
        </v-card>
    </v-dialog>
    <v-dialog v-model="editPlaceDialog" max-width="600">
        <v-card>
            <v-toolbar flat color="#66bb6a">
              <v-toolbar-title left color="blue" class="text--white">Редактирование точки продаж</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="editPlaceDialog = false">
                  <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
          <v-card-text>
            <v-form ref="form" v-model="valid">
                          
                          
                <v-layout wrap>
                    <v-flex xs4 class="font-weight-bold">Название</v-flex>
                    <v-flex xs8>
                        <v-text-field :rules="rules.required"
                                      label="Название" 
                                      v-model="editPlace.name" 
                                      ></v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout wrap>
                    <v-flex xs4 class="font-weight-bold">Адрес</v-flex>
                    <v-flex xs8>
                        <v-textarea :rules="rules.required"
                                    label="Описание" 
                                    v-model="editPlace.address"></v-textarea>
                    </v-flex>
                </v-layout>
                
                
                <v-layout wrap>
                    <v-flex xs4 class="font-weight-bold">Валюта</v-flex>
                    <v-flex xs8>
                        <v-select label="Выберите валюту"
                                  :items="currencyItems"
                                  :rules="rules.required"
                                  v-model="editPlace.currency"></v-select>
                    </v-flex>
                </v-layout>
                <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Номер венданалитики</v-flex>
                  <v-flex xs8>
                      <v-textarea 
                                  label="Серийный номер в венданалитике" 
                                  v-model="editPlace.vendAnaliticaId"></v-textarea>
                  </v-flex>
                </v-layout>
                <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Родительский контроль</v-flex>
                  <v-flex xs8>
                    <v-checkbox
                      v-model="editPlace.hasAccessControl"
                      label="Ограничить доступ к запрещенным категориям"
                    ></v-checkbox>
                  </v-flex>
                </v-layout>
                <v-layout wrap>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" @click="updatePlace" class="white--text" :disabled="!valid">Сохранить</v-btn>
                    <v-btn color="red darken-1" @click.native="editPlaceDialog = false" class="white--text">Отмена</v-btn>
                </v-layout>
            </v-form>
          </v-card-text>
        </v-card>
    </v-dialog>
    <v-dialog v-model="deletePlaceDialog" max-width="600">
      <v-card>
          <v-toolbar flat color="#66bb6a">
            <v-toolbar-title left color="blue" class="text--white">Удаление точки продаж</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.native="deletePlaceDialog = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        <v-card-text>
          <v-alert
              :value="error != null"
              type="error"
            >
              {{ error }}
            </v-alert>
          <v-layout wrap>
            Вы действительно хотите удалить {{ deletePlace.name }}? Точка продаж не должно содержать ни одного автомата.
          </v-layout>
          <v-layout wrap>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" @click="delPlace" class="white--text">Удалить</v-btn>
            <v-btn color="red darken-1" @click.native="deletePlaceDialog = false" class="white--text">Отмена</v-btn>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    error: null,
    valid: false,
    addPlaceDialog: false,
    editPlaceDialog: false,
    deletePlaceDialog: false,
    headers: [
      {
        sortable: false,
        text: '',
        value: ''
      },
      {
        sortable: false,
        text: 'Название',
        value: 'name'
      },
      {
        sortable: false,
        text: 'Адрес',
        value: 'address'
      },
      {
        sortable: false,
        text: 'Валюта',
        value: 'currency'
      }
    ],
    items: [
      
    ],
    currencyItems: ["Рубль", "Доллар", "Евро"],
    newPlace: {
      address: null,
      name: null,
      currency: null,
      vendAnaliticaId: null,
      hasAccessControl: false
    },
    editPlace:{},
    deletePlace: {},
    rules: {
        required: [v => !!v || 'Поле обязательно',],
    },
  }),
  mounted () {
    this.getPlaces()
  },
  methods: {
    pickToEditPlace(item){
      this.editPlace = item;
      this.editPlaceDialog = true;
    },
    pickToDeletePlace(item){
      this.deletePlace = item;
      this.deletePlaceDialog = true;
    },
    getPlaces (){
      this.$http.get('/places')
        .then(response => {
          this.items = response.data
          console.log(this.items)
        })
    },
    addPlace(){
      this.$http.post('/places', this.newPlace).
          then(response => {
            this.addPlaceDialog = false;
            this.getPlaces();
          }).
          catch(e => {
              console.log(e)
          });
    },
    updatePlace(){
      this.$http.put('/places/'+this.editPlace.id, this.editPlace).
          then(response => {
            this.editPlaceDialog = false;
            this.getPlaces();
          }).
          catch(e => {
              console.log(e)
          });
    },
    delPlace(){
      this.error = null;
      this.$http.delete('/places/' + this.deletePlace.id).
        then(response => {
          this.deletePlaceDialog = false;
          this.getPlaces();
        }).
        catch(e => {
          this.error = e.response.data;
            console.log(e)
        });
    }
  }
}
</script>
